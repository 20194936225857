import { useTheme, Text, View } from "@aws-amplify/ui-react";

export function Footer() {
  const { tokens } = useTheme();

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Text>
        By signin in, you agree to our{" "}
        <a href="https://atomberg.com/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: "blue", textDecoration: "none" }}>
          Privacy Policy
        </a>
      </Text>
    </View>
  );
}
