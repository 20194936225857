import { useAuth } from "../AuthContext";
import { getCurrentUser } from "aws-amplify/auth";
import React, { useEffect, useState } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { SignInHeader } from "./SignInHeader";
import "./AccountInfo.css";
import { withAuthenticator, Button, Heading, Image, View, Card } from "@aws-amplify/ui-react";

const AccountInfo = () => {
  const [loading_here, setLoading] = useState(true);
  const { user, loading, setUser } = useAuth();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async (username) => {
      try {
        const response = await fetch(`https://h8nr9aup46.execute-api.ap-south-1.amazonaws.com/prod/get_usage_token?username=${username}`, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        if (result.status == "Success") {
          setData(result.data);
          setMessage(result.message);
        } else {
          setError(result.message);
        }
      } catch (error) {
        setError("An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };

    const checkUser = async () => {
      const user = await getCurrentUser();
      setUser(user);
      const { username, userId, signInDetails } = await getCurrentUser();
      if (user != null) {
        fetchData(username);
      }
    };

    checkUser();
  }, [setUser]);

  if (loading_here) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>{message}</h2>
      <br></br>
      <table className="styled-table">
        <thead>
          <tr>
            {/* Replace with your data keys */}
            <th>Usage plan</th>
            <th>API Limit</th>
            <th>Throttle</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* Replace with your data keys */}
            <td>{data.plan}</td>
            <td>{data.limit}</td>
            <td>{data.throttle}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default withAuthenticator(AccountInfo, { components: { Header, SignIn: { Header: SignInHeader, Footer: Footer } }, socialProviders: ["google", "apple", "facebook"], hideSignUp: true });
