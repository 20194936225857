import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";

const Navbar = () => {
  const { user, loading, setUser } = useAuth();
  return (
    <nav>
      <ul>
        <li>
          <Link to="/">Docs</Link>
        </li>
        <li>
          <Link to="/accountinfo">Account Info</Link>
        </li>
        <li style={{ float: "right" }}>
          <Link to={user ? "/signout" : "/signin"}>{user ? "Sign Out" : "Sign In"}</Link>
        </li>
        {/* <li><Link to="/projects">Projects</Link></li>
                <li><Link to="/contact">Contact</Link></li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
