import "@aws-amplify/ui-react/styles.css";

import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { signOut } from "aws-amplify/auth";
import { useAuth } from "../AuthContext";

function SignOut() {
  const navigate = useNavigate();
  const { user, loading, setUser } = useAuth();

  useEffect(() => {
    doSignOut();
  }, []);

  const doSignOut = async () => {
    signOut();
    setUser(null);
    navigate("/");
  };

  return <div>"Signed Out Successfully"</div>;

  //   useEffect(() => {
  //     const checkUser = async () => {
  //         const user = await Auth.currentAuthenticatedUser();
  //         setUser(user);
  //         navigate('/accountinfo');
  //       };

  //     const unsubscribe = Hub.listen("auth", ({ payload }) => {
  //       switch (payload.event) {
  //         case "signInWithRedirect":
  //           getUser();
  //           break;
  //         case "signInWithRedirect_failure":
  //           setError("An error has occurred during the Oauth flow.");
  //           break;
  //         case "customOAuthState":
  //           setCustomState(payload.data);
  //           break;
  //       }
  //     });

  //     getUser();

  //     return unsubscribe;
  //   }, []);

  //   const getUser = async () => {
  //     try {
  //       const currentUser = await getCurrentUser();
  //       setUser(currentUser);
  //     } catch (error) {
  //       console.error(error);
  //       console.log("Not signed in");
  //     }
  //   };

  //   return (
  //     <View className="SignIn">
  //       <Card>
  //         <Image src={logo} className="App-logo" alt="logo" />
  //         <Heading level={1}>We now have Auth!</Heading>
  //       </Card>
  //       <Button onClick={signOut}>Sign Out</Button>
  //     </View>
  //   );
}

export default SignOut;
