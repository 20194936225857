import React from "react";
import "rapidoc";
import atombergLogoDark from "./atomberg-logo-dark.png";

function Documentation() {
  return (
    <rapi-doc
      spec-url="https://developer.atomberg-iot.com/openapi.yaml"
      theme="dark"
      regular-font="Roboto"
      allow-spec-file-download="false"
      render-style="focused"
      show-method-in-nav-bar="as-colored-text"
      primary-color="#fdb913"
      bg-color="#141414"
      nav-bg-color="#2C2C2C"
      nav-accent-color="#fdb913"
      nav-item-spacing="relaxed"
      show-header="false"
    >
      <img slot="nav-logo" src={atombergLogoDark} />
      <div slot="servers">
        <hr />
        <br />
        <br />
        All APIs can return the following status codes:
        <br />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              "\n    .tg  {border-collapse:collapse;border-spacing:0;}\n    .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n      overflow:hidden;padding:10px 5px;word-break:normal;}\n    .tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n      font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}\n    .tg .tg-fymr{border-color:inherit;font-weight:bold;text-align:left;vertical-align:top}\n    .tg .tg-0pky{border-color:inherit;text-align:left;vertical-align:top}\n    ",
          }}
        />
        <table className="tg">
          <thead>
            <tr>
              <th className="tg-fymr">Code</th>
              <th className="tg-fymr">Description</th>
              <th className="tg-fymr">Scenario</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tg-0pky">200</td>
              <td className="tg-0pky">OK</td>
              <td className="tg-0pky">The request executed successfully</td>
            </tr>
            <tr>
              <td className="tg-0pky">401</td>
              <td className="tg-0pky">Unauthorized</td>
              <td className="tg-0pky">Typically encountered when using an expired access token</td>
            </tr>
            <tr>
              <td className="tg-0pky">403</td>
              <td className="tg-0pky">Forbidden</td>
              <td className="tg-0pky">Typically encountered if developer mode is disabled</td>
            </tr>
            <tr>
              <td className="tg-0pky">404</td>
              <td className="tg-0pky">Not found</td>
              <td className="tg-0pky">Typically encountered when the device you are trying to interact with doesn't belong to your account</td>
            </tr>
            <tr>
              <td className="tg-0pky">429</td>
              <td className="tg-0pky">Too many requests</td>
              <td className="tg-0pky">Typically encountered after you hit any API limit</td>
            </tr>
          </tbody>
        </table>
      </div>
    </rapi-doc>
  );
}

export default Documentation;
