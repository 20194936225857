import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import Documentation from "./components/Documentation";
import SignOut from "./components/SignOut";
import AccountInfo from "./components/AccountInfo";
import { AuthProvider, useAuth } from "./AuthContext";

import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: "ap-south-1_fMZyKIlR2",
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: "5k8o910vi3mi8ve94pq0s41p8v",
      // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      allowGuestAccess: false,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: "code", // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: "auth.atomberg-iot.com",
          scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
          // socialProviders: ["FACEBOOK", "GOOGLE", "APPLE"],
          redirectSignIn: ["http://localhost:3000", "https://app.atomberg-iot.com", "https://demo.developer.atomberg-iot.com", "https://staging.djzu1uw8iv03w.amplifyapp.com/"],
          redirectSignOut: ["http://localhost:3000", "https://app.atomberg-iot.com", "https://demo.developer.atomberg-iot.com", "https://staging.djzu1uw8iv03w.amplifyapp.com/"],
          responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
});

const ProtectedRoute = ({ element }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? element : <Navigate to="/signin" />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Documentation />,
      },
      {
        path: "/signin",
        element: <AccountInfo />,
      },
      {
        path: "/signout",
        element: <SignOut />,
      },
      {
        path: "/accountinfo",
        element: <ProtectedRoute element={<AccountInfo />} />,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <RouterProvider router={router}>
      <App />
    </RouterProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
